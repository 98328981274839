import { render, staticRenderFns } from "./ZSD-NC-Resultaten-Quickscan-Over-Tijd.vue?vue&type=template&id=ba4da64a&scoped=true"
import script from "./ZSD-NC-Resultaten-Quickscan-Over-Tijd.vue?vue&type=script&lang=js"
export * from "./ZSD-NC-Resultaten-Quickscan-Over-Tijd.vue?vue&type=script&lang=js"
import style0 from "./ZSD-NC-Resultaten-Quickscan-Over-Tijd.vue?vue&type=style&index=0&id=ba4da64a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba4da64a",
  null
  
)

export default component.exports