<template>
  <vue-plotly style="width:100%;" :data="plot_data" :layout="layout" :options="options"/>
</template>

<script>

import VuePlotly from '@statnett/vue-plotly'

export default {
name: 'GroupedBarChart',
components: { VuePlotly },
props: {
  ytitle: {
    type: String,
    default: ''
  },
  xtitle: {
    type: String,
    default: ''
  },
  ticktext: {
    type: Array,
        default: () => []
  },
  tickvals: {
    type: Array,
        default: () => []
  },
  names: {
    type: Array,
    default: () => []
  },
  x: {
    type: Array,
    default: () => []
  },
  y: {
    type: Array,
    default: () => []
  },
  showlegend: {
    type: Boolean,
    default: true
  },
  height: {
    type: Number,
    default: 1420
  },
  margintop: {
    type: Number,
    default: 40
  },
  title: {
    type: String,
    default: ''
  },
  marginBottom: {
    type: Number,
    default: 140
  },
  tickangle: {
    type: Number,
    default: 45
  },
  niveau: {
    type: String,
    default: ''
  },
  tickformat:{
    type: String,
    default: ''
  },
  ticksize:{
    type: Number,
    default:10
  }
},
data () {
  return {
    text: [],
    colorbars: [],
    layout: {
      hovermode: 'closest',
      paper_bgcolor: 'rgba(0,0,0,0)',
      plot_bgcolor: 'rgba(0,0,0,0)',
      margin: {
        l: 50,
        r: 100,
        b: this.marginBottom,
        t: this.margintop,
        pad: 2},
      barmode: 'group',
      uniformtext:{
        minsize:8,
        mode:'hide'},
      showlegend: this.showlegend,
      height: this.height,
      xaxis: {
        automargin: true,
        ticktext: this.ticktext,
        tickvals: this.dynamicTickvals,
        tickangle: this.tickangle,
        tickfont:{
          size: this.ticksize
        },
        title: {
          standoff: 40,
          text: this.xtitle,
          font: {
            family: 'Gotham',
            size: 12
          },
        }
      },
      title: {
        text: this.title,
        font: {
          family: 'Gotham',
          size: 12
        },
        xref: 'paper',
        x: 0.05,
        y: 0.99,
      },
      yaxis: {
        automargin: true,
        title: {
          standoff: 20,
          text: this.ytitle,
          font: {
            family: 'Gotham',
            size: 12
          },
        },
        tickformat: this.tickformat,
      }
    },
    options: {
      displayModeBar: false,
      responsive: true,
      maintainAspectRatio: false
    }
  }
},
computed: {
  dynamicTickvals() {
    if(typeof this.x === 'string'){
      return this.x;
    }
    else if (Array.isArray(this.x) && this.x.length && Array.isArray(this.x[0])) {
      let uniqueX = [...new Set([].concat(...this.x))];
      return uniqueX;
    } 
    else {
      //if x-axis data is a single array
      return this.x;
    }
  },
  plot_data() {
    var plot_data = [];
    for (var i = 0; i < this.x.length; i++) {
      const trace = {
        type: 'bar',
        x: this.x[i],
        y: this.y[i],
        // this text is the direct label on the graph
        text: this.text[i],
        textfont: { size: 10 },
        textposition: 'inside',
        textangle: 0,
        hoverinfo: this.x[i],
        orientation: 'h',
        marker: {
          color: this.colorbars[i]
        }
      };

      // conditionally add name property only if names prop is provided
      if (this.names && this.names.length > 0) {
        trace.name = this.names[i];
      } else {
        trace.showlegend = false; // hide legend for this trace
      }

      plot_data.push(trace);
    }
    return plot_data;
  }

},
watch: {
  '$store.state.selectedRegion': function () {
    this.setColors()
  },
  x () {
    this.setColors()
    this.setXtitle()
    this.setText()
  },
  y () {
    this.setText()
  }
},
mounted () {
  this.setColors()
  this.setXtitle()
  this.setText()
},
methods: {
  setText () {
    this.text = []
      for (var i = 0; i < this.x.length; i++) {
      this.text.push(this.x[i].map(d => {
        if (d > 0 && d < 1) {
          return (d * 100).toFixed(0)
        } else {
          return d.toFixed(0)
        }
      }))
    }
  },
  setXtitle () {
    if (this.x[0].length>35) {
      this.layout.xaxis.title.text='Dementienetwerk'
    }
    else if (this.niveau==='dementienetwerk') {
      this.layout.xaxis.title.text='Dementienetwerk'
    }
    else {
      this.layout.xaxis.title.text= this.xtitle
    }
  },
  setColors () {
    this.colorbars = []
    
    var opacities = Array.from({length: this.x.length}, (_, i) => i + 1).reverse()
    for(var k = 0; k < opacities.length; k++){
        opacities[k] /= this.x.length
    }

    for (var j = 0; j < this.x.length; j++) {
      var colorArray = [];
      for (var i = 0; i < this.x[j].length; i++) {
        if (this.x[j][i] === this.$store.state.selectedRegion) {
          colorArray.push(`rgba(20,66,115,${opacities[j]}))`)
        }
        else if (this.x[j][i] === 'Landelijk') {
          colorArray.push(`rgba(51,127,100,${opacities[j]}))`)
        }
        else if (this.xtitle === 'Jaar') {
          colorArray.push(`rgba(20,66,115,${opacities[j]}))`)
        }
        else if (this.xtitle === 'Vragen') {
          colorArray.push(`rgba(20,66,115,${opacities[j]}))`)
        }
        else {
          colorArray.push(`rgba(140, 140, 140,${opacities[j]})`)
        }
      }
      this.colorbars.push(colorArray)
    }
    return
  }
}
}

</script>