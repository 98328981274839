<template>
  <div class="about" id="dementie-information">
  <p v-if="errorMessage" v-bind:class="{'error': errorMessage}" v-bind:style="{ color: 'red' }">{{ errorMessage }}</p>
    <!-- Only display the data if the errorMessage property is not set -->
  <div v-if="!errorMessage">
    <h1>Quickscan resultaten over tijd</h1>

        <div class="row down">
          <card-extra-large :hovertext="''">
              <template slot="card-header" >Alle vragen over tijd </template>
              <div slot="card-body">
                  <horizontal-grouped-bar-chart 
                  :names="['2020', '2021', '2022', '2023', '2024']"
                  :tickvals="[questions]"
                  :ticktext="[all_data.map(d => (d.vraag))]"
                  :xtitle="'Antwoord'" 
                  :ytitle="''" v-if="load.graphOne" 
                  :x= "[all_data.filter(item => item.jaar === jaren[4]).map(d => (d.antwoord_id)), 
                  all_data.filter(item => item.jaar === jaren[3]).map(d => (d.antwoord_id)), 
                  all_data.filter(item => item.jaar === jaren[2]).map(d => (d.antwoord_id)), 
                  all_data.filter(item => item.jaar === jaren[1]).map(d => (d.antwoord_id)), 
                  all_data.filter(item => item.jaar === jaren[0]).map(d => (d.antwoord_id))]"
                  :y="[all_data.filter(item => item.jaar === jaren[4]).map(d => (d.vraag)), 
                  all_data.filter(item => item.jaar === jaren[3]).map(d => (d.vraag)), 
                  all_data.filter(item => item.jaar === jaren[2]).map(d => (d.vraag)), 
                  all_data.filter(item => item.jaar === jaren[1]).map(d => (d.vraag)), 
                  all_data.filter(item => item.jaar === jaren[0]).map(d => (d.vraag))]" />
              </div>
          </card-extra-large>
        </div>
        <button class="btn btn-secondary" @click="backToMenu" >Terug naar menu</button>
      </div>
</div>


</template>

<script>
  import CardExtraLarge from '../components/CardExtraLarge'
  import HorizontalGroupedBarChart from '../components/HorizontalGroupedBarChart'
  import VueMeta from 'vue-meta'
  
  export default{
    mixins: [VueMeta],
    metaInfo: {
      title: 'Quickscan Over Time Resultaten',
    },
    name: 'QuickscanOverTimeResultaten',
    components: {CardExtraLarge, HorizontalGroupedBarChart}, 
    data(){ 
      return {
        all_data: [],
        jaren: [],
        questions: [],
        errorMessage: '',
        load: {
          graphOne: false,
        }
      }
  },

  methods: {
    loadData() {
      this.$http.get(`api/netwerkcoordinators/get_data_over_time/`).then(
        (response) => {
        this.all_data = response.data
        this.all_data.sort((a,b) => b.vraag_id-a.vraag_id)
        this.jaren = [...new Set(this.all_data.map(item => item.jaar))]
        this.load.graphOne = true
        }
      ).catch(error => {
          console.error(error)
          if(error.response.status == 500){
            this.errorMessage = 'Er is nog geen quickscan ingevuld. Zodra ten minste 1 netwerkcoordinator de quickscan heeft ingevuld, zijn de resultaten over tijd hier te zien.' 
          }
          else if(error.response.status == 401){
            this.errorMessage = 'U hebt geen toegang tot deze pagina.' 
          }
          else {
            this.errorMessage = 'Onbekende foutmelding. Contacteer alstublieft dementiedata@amsterdamdatacollective.com.'
          }
    }),
      this.$http.get(`/api/vragen_quickscan/`).then(
        (response) => {
        response.data.sort((a,b) => a.id-b.id)
        this.questions = response.data.map(d => (d.vraag))
        }
      )
    },
    backToMenu() {
      this.$router.push('/zorgstandaard/Netwerkcoordinator/')
    }
  },

  mounted () {
    this.loadData()
  }
}


</script>

<style scoped>
  
  .btn-secondary {
    background: var(--color-1);
    font-weight: bold;
    height: 50px;
    padding-top: 5px !important;
    z-index: 12;
    margin-top: 30px;
    margin-right: 50px;
    width: 200px;
    max-width: 300px
  }
</style>