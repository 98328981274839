var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about",attrs:{"id":"dementie-information"}},[(_vm.errorMessage)?_c('p',{class:{'error': _vm.errorMessage},style:({ color: 'red' })},[_vm._v(_vm._s(_vm.errorMessage))]):_vm._e(),(!_vm.errorMessage)?_c('div',[_c('h1',[_vm._v("Quickscan resultaten over tijd")]),_c('div',{staticClass:"row down"},[_c('card-extra-large',{attrs:{"hovertext":''}},[_c('template',{slot:"card-header"},[_vm._v("Alle vragen over tijd ")]),_c('div',{attrs:{"slot":"card-body"},slot:"card-body"},[(_vm.load.graphOne)?_c('horizontal-grouped-bar-chart',{attrs:{"names":['2020', '2021', '2022', '2023', '2024'],"tickvals":[_vm.questions],"ticktext":[_vm.all_data.map(d => (d.vraag))],"xtitle":'Antwoord',"ytitle":'',"x":[_vm.all_data.filter(item => item.jaar === _vm.jaren[4]).map(d => (d.antwoord_id)), 
                  _vm.all_data.filter(item => item.jaar === _vm.jaren[3]).map(d => (d.antwoord_id)), 
                  _vm.all_data.filter(item => item.jaar === _vm.jaren[2]).map(d => (d.antwoord_id)), 
                  _vm.all_data.filter(item => item.jaar === _vm.jaren[1]).map(d => (d.antwoord_id)), 
                  _vm.all_data.filter(item => item.jaar === _vm.jaren[0]).map(d => (d.antwoord_id))],"y":[_vm.all_data.filter(item => item.jaar === _vm.jaren[4]).map(d => (d.vraag)), 
                  _vm.all_data.filter(item => item.jaar === _vm.jaren[3]).map(d => (d.vraag)), 
                  _vm.all_data.filter(item => item.jaar === _vm.jaren[2]).map(d => (d.vraag)), 
                  _vm.all_data.filter(item => item.jaar === _vm.jaren[1]).map(d => (d.vraag)), 
                  _vm.all_data.filter(item => item.jaar === _vm.jaren[0]).map(d => (d.vraag))]}}):_vm._e()],1)],2)],1),_c('button',{staticClass:"btn btn-secondary",on:{"click":_vm.backToMenu}},[_vm._v("Terug naar menu")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }