<template>
    <section slot="pdf-content">
      <div class="card down">



        <div class="card-header">
          <div class="row">
            <div class = "col-sm-10" @click="toggleCollapse()">
              <font-awesome-icon icon="chevron-circle-down" v-if="collapse" />
              <font-awesome-icon icon="chevron-circle-up" v-else />
              &nbsp;
              <slot name="card-header" />
            </div>
            <div class = "col-sm-1 right">
              <font-awesome-icon icon="info" v-b-popover.hover.focus="hovertext"/><br>
            </div>
            <!-- <div class = "col-sm-1 right" @mouseover="hover = true" @mouseleave="hover = false">
              <font-awesome-icon v-if="hover">this graph is about</font-awesome-icon>
              <font-awesome-icon icon="info" v-else />
            </div> -->
            <div class = "col-sm-1">
              <font-awesome-icon icon="download" @click="printPDF()"/><br>
            </div>
          </div>
        </div>

        <div class="card-body" v-if="!collapse">
          <slot name="card-body" />
        </div>


      </div>
    </section>
</template>

<script>

// import VueHtml2pdf from 'vue-html2pdf'

export default {
  name: 'Card',
  // components: {
  //       VueHtml2pdf
  //     },
  props: {
    hovertext: String
  },
  data () {
    return {
      collapse: false,
      hover: false,
    }
  },
  methods: {
    toggleCollapse: function () {
      this.collapse = !this.collapse
    },
    printPDF () {
      window.print()
    },
    // generateReport () {
    //         this.$refs.html2Pdf.generatePdf()
    // }
  },
}

</script>

<style scoped>

.card-header:hover {
  cursor: pointer;
}

.card-header {
  height: 30px;
  padding: 5px 5px 5px 20px;
  color: white;
  background-color: var(--color-1) !important;
  border-color: var(--color-1) !important;
  text-align: left;
}

.card-body {
  background-color: white !important;
  border: 1px var(--color-4) solid;
  text-align: left;
  font-size: 12px;
  font-style: italic;
  height: 1350px;
/*  max-height: 80vh;
  overflow-y: auto;*/
}

.right {
  margin-left: 10px;
}

.col-sm-1 {
  margin-right: -20px;
}

</style>
